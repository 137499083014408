import React from "react";

import overwhelmed from "./assets/images/softeng.webp";
const HomeImages4 = (props) => {
  return (
    <div className="col text-center">
      <img className="w-50 mr-1 rotate" src={overwhelmed} alt="EasyCryptoBot Overwhelmed from trading?" />
    </div>
  );
};

export default HomeImages4;
